<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-lg nk-content-fluid">
      <div class="container-xl wide-lg">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head">
              <div class="nk-block-between-md g-3">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <span
                      >¡Bienvenido {{ user.name }} {{ user.lastname }}!</span
                    >
                  </div>
                  <div class="align-center flex-wrap pb-2 gx-4 gy-3">
                    <div>
                      <h2 class="nk-block-title fw-normal">
                        {{ config != null ? config.nombre : "" }}
                      </h2>
                    </div>
                  </div>
                </div>
                <!-- .nk-block-head-content -->
                <div class="nk-block-head-content d-none d-md-block"></div>
                <!-- .nk-block-head-content -->
              </div>
              <!-- .nk-block-between -->
            </div>
            <!-- .nk-block-head -->
            <div class="nk-block">
              <h5>Estadísticas</h5>
            </div>
            <hr />
            <div class="nk-block">
              <div class="row gy-gs">
                <div class="col-md-6 col-lg-3">
                  <div class="nk-wg-card is-dark card card-bordered">
                    <div class="card-inner">
                      <div class="nk-iv-wg2">
                        <div class="nk-iv-wg2-title">
                          <h6 class="title">
                            Empleados Total <em class="icon ni ni-info"></em>
                          </h6>
                        </div>
                        <div class="nk-iv-wg2-text">
                          <div class="nk-iv-wg2-amount">
                            {{ empleados.total }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->
                <div class="col-md-6 col-lg-3">
                  <div class="nk-wg-card is-s2 card card-bordered">
                    <div class="card-inner">
                      <div class="nk-iv-wg2">
                        <div class="nk-iv-wg2-title">
                          <h6 class="title">
                            Empleados Pendientes
                            <em class="icon ni ni-info"></em>
                          </h6>
                        </div>
                        <div class="nk-iv-wg2-text">
                          <div class="nk-iv-wg2-amount">
                            {{ empleados.pendientes }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->
                <div class="col-md-12 col-lg-3">
                  <div class="nk-wg-card is-s3 card card-bordered">
                    <div class="card-inner">
                      <div class="nk-iv-wg2">
                        <div class="nk-iv-wg2-title">
                          <h6 class="title">
                            Empleados Activos <em class="icon ni ni-info"></em>
                          </h6>
                        </div>
                        <div class="nk-iv-wg2-text">
                          <div class="nk-iv-wg2-amount">
                            {{ empleados.activos }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->
                <div class="col-md-12 col-lg-3">
                  <div class="nk-wg-card is-s4 card card-bordered">
                    <div class="card-inner">
                      <div class="nk-iv-wg2">
                        <div class="nk-iv-wg2-title">
                          <h6 class="title">
                            Empleados Baja <em class="icon ni ni-info"></em>
                          </h6>
                        </div>
                        <div class="nk-iv-wg2-text">
                          <div class="nk-iv-wg2-amount">
                            {{ empleados.baja }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->
              </div>
              <!-- .row -->
            </div>
            <!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import { defineComponent, reactive, ref, toRefs } from "vue";
import Swal from "sweetalert2";
import { useApi } from "@/use/useApi";
import useAuth from "@/providers/auth";

export default defineComponent({
  name: "Resumen",
  components: {
    AppView,
  },
  setup() {
    const { user } = useAuth();

    const { loading, make } = useApi();

    const state = reactive({
      empleados: {
        total: 0,
        pendientes: 0,
        activos: 0,
        baja: 0,
      },
    });

    const config = ref();

    async function get() {
      loading.message = "Obteniendo información";
      let { data } = await make("reportes/dashboard/get", {});
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          state.empleados = data.empleados;
        }
      }
    }

    async function getConfig() {
      let { data } = await make("public/get-config", {});
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          config.value = data.model;
        }
      }
    }

    get();
    getConfig();

    return { ...toRefs(state), config, user };
  },
});
</script>

<style scoped>
.nk-wg-card.is-s2:after {
  background: var(--warning);
}
</style>